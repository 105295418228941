import { theme } from './routes/theme'
import AppRoutes from './routes';
import { CssBaseline, Toolbar } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import Topbar from './routes/topbar';
import Footer from './routes/footer';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Topbar />
        <Toolbar />
        <AppRoutes />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
