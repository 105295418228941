import React from 'react'
import { Grid, Typography, Link } from '@mui/material'
import Logo from './homepage/logo'

export default function Footer() {

    const footerLinks = [
        {
            name: 'Service66',
            url: 'https://service66.mazeyard.com'
        },
        {
            name: 'Apps',
            url: 'https://www.mazeyard.com/apps'
        },
        {
            name: 'Contact Us',
            url: 'https://www.mazeyard.com/contact'
        },
    ]

    return <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ bgcolor: 'primary.main', paddingTop: 10 }}
    >
        <Grid item xs={12} md={6} sx={{ paddingTop: 10, paddingBottom: 10 }} >
            <Logo color="white" />
            <br />
            <br />
            <Typography variant="h6" sx={{ color: 'white' }}>
                A subsidiary of
                <Link
                    href="https://www.mazeyard.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: 'white' }}
                >
                    &nbsp;MazeYard
                </Link>
            </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ paddingTop: 10, paddingBottom: 10 }}>
            {
                footerLinks.map((link, index) => {
                    return <Typography variant='body1' sx={{ marginBottom: 1 }} key={index}>
                        <Link
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: 'white' }}
                        >
                            {link.name}
                        </Link>
                    </Typography>
                })
            }
        </Grid>
    </Grid>
}