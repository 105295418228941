import { Box, Typography, Grid } from '@mui/material'
import React from 'react'
import Logo from './logo'

export default function Build() {

    return <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ marginTop: 5, marginBottom: 5 }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ margin: '0 auto', marginTop: 15, marginBottom: 10, }}
            >
                <Typography variant="h6">
                    Create what you can.
                </Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginTop: 5, marginBottom: 5 }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ margin: '0 auto', marginTop: 10, marginBottom: 10, overflow: 'hidden', width: '200px', cursor: 'pointer' }}
                onClick={() => window.location.href = 'https://build.withmazeyard.com'}
            >
                <Typography
                    variant="h3"
                    sx={{
                        letterSpacing: '17px', marginRight: '-12px', marginBottom: -1.5, marginLeft: '5px',
                        borderBottom: '1px solid black', width: '200px', overflowX: 'hidden'
                    }}
                >
                    Build
                </Typography>
                <Box sx={{ transform: 'scale(0.8, 0.8)' }}>
                    <Logo />
                </Box>
            </Grid>
        </Grid>
    </Grid >
}