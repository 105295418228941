import React from 'react'
import Logo from './logo'
import { Grid, Typography } from '@mui/material'

export default function Explore() {

    return <Grid
        container
        spacing={0}
        sx={{ height: `calc(100vh - 60px)` }}
    >
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12} md={6}>
                <Typography variant="h2">
                    Explore
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Logo />
            </Grid>
        </Grid>
    </Grid>
}