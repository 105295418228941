import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function Logo(props) {

    var { color } = props
    if (!color) {
        color = 'black'
    }

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ overflow: 'hidden', color: color }}
    >
        <Typography variant="h6"
            sx={{ letterSpacing: '52px', marginRight: '-50px', padding: 0 }}>
            WITH
        </Typography>
        <Typography
            variant="h3"
            sx={{ marginTop: '-6px', borderTop: `1px solid ${color}`, width: '220px' }}
        >
            MazeYard
        </Typography>
    </Grid>
}