import React from 'react'
import Build from './build'
import Explore from './explore'
import Possibilities from './possibilities'

export default function HomePage() {

    return <>
        <Explore />
        <Possibilities />
        <Build />
    </>
}