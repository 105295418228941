import { Grid, Typography } from "@mui/material"
import React from "react"

export default function Possibilities() {

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ bgcolor: 'primary.main', height: '100vh' }}
    >
        <Typography
            variant="h3"
            sx={{ color: 'white' }}
        >
            Countless Possibilities
        </Typography>
        <Typography
            variant="h5"
            sx={{ color: 'white', marginTop: 10 }}
        >
            One Platform
        </Typography>
    </Grid>
}